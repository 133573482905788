<template>
    <manual-popover
        :show="show"
        target="product-stay-group-container"
        @clickOutside="closeInput"
        placement="leftbottom"
    >
        <product-stay-group/>
    </manual-popover>
</template>
<script>
// TODO  container="mr1" need on popover ??

    import { mapActions, mapGetters } from 'vuex'
    import ManualPopover from "Components/ui/ManualPopover"
    import ProductStayGroup from 'Components/container/Product/ProductStayGroupForm'

    export default {
        name:'ProductStayGroupPopover',
        components: {
            ManualPopover,
            ProductStayGroup,
        },
        computed: {
            ...mapGetters({
                show : 'product/showPopoverGroup',
            }),
        },
        methods : {
            ...mapActions({
                closeInput : 'product/closeInput'
            })
        }
    }
</script>
