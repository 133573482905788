import axios from 'axios'

export const translate = async (txt, to) => {

  const params = {
    "q": txt,
    "target": to,
    "format": "text",
    "key" : GOOGLE_TRANSLATE_API_KEY
  }
  const { data } = await axios.get('https://translation.googleapis.com/language/translate/v2', { params })

  return getFirstResultFromGoogleTranslateResponse(data)
}

const getFirstResultFromGoogleTranslateResponse = (data) => {
  if(data
    && data.data
    && data.data.translations
    && data.data.translations[0]
    && data.data.translations[0].translatedText){
    return data.data.translations[0].translatedText
  }
  console.warn(`No translation found ! Data for ${data}!`)
  return '';
}
