<template>
    <b-button variant="success" @click="$emit('click')">{{ $t('Je valide') }}</b-button>
</template>

<script>
    import { BButton } from 'bootstrap-vue'
    export default {
        name: "ValidateButton",
        components :{
            BButton
        }
    }
</script>
