import dayjs from 'dayjs'
import i18n from "Plugins/i18n"

/**
 *
 * @param {string} start
 * @param {string} end
 * @param {string} format
 * @returns {string}
 */
export const getDateTextFromTo = ({start, end, format = 'D MMM YYYY'}) => {
    return i18n.t("Du {startDate} au {endDate}", {
        startDate: dayjs(start).format(format),
        endDate: dayjs(end).format(format)
    })
}
/**
 *
 * @param {string} start
 * @param {string} end
 * @param {string} type
 * @param {string} month
 * @returns {string}
 */
export const getDateTextFromStayDate = ({start, end, type, month,}) => {
    if(start && end) {
        return getDateTextFromTo({start, end})
    }

    if(month) {
        return `${getStayTypeTxt(type)}, ${dayjs(month).format('MMMM YYYY')}`
    }

    if(type) {
        return getStayTypeTxt(type)
    }

    return i18n.t("Quand voulez-vous partir ?")
}

/**
 *
 * @param {string} type
 * @returns {string}
 */
export const getStayTypeTxt = (type) => {
    return i18n.t(`__AEV5_type sejour_${type}`)
}

/**
 *
 * @param {number} personCount
 * @param {number} dogCount
 * @param {boolean} placeHolder Faut-il un placeholder si rien de rempli ?
 * @returns {string}
 */
export const getStayGroupTxt = ({personCount, dogCount}, placeHolder = false) => {
    if(personCount){
        let trad = "";

        trad += i18n.tc("__i18n_Nombre personne mr1", personCount, {personCount})
        if(dogCount){
            trad += `, ${ i18n.tc("__i18n_Nombre chien mr1", dogCount, {dogCount})}`
        }
        return trad
    }
    if(placeHolder){
        return i18n.t("Avec qui partez-vous ?")
    }
    return ""
}
