<template>
  <div v-if="hasDiscount" class="row promo">
    <div class="col-6">
        <span class="label">
          {{ $t("Promotion") }}</span>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <span class="value"><price-format :amount="discountMinus"/></span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import PriceFormat from 'Components/ui/PriceFormat'

export default {
  name: 'StayDiscount',
  components: { PriceFormat },
  computed: {
    ...mapGetters({
      hasDiscount:'product/staySelected/hasDiscount',
      discountMinus:'product/staySelected/discountMinus',
    })
  }
}
</script>

<style scoped>

</style>
