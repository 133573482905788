<template>
    <b-button-group>
        <b-button
                ref="button"
                :id="id"
                variant="light"
                @click="onClick">
            <slot></slot>
        </b-button>
        <b-button
                class="removeButton"
                variant="light"
                v-if="showRemove"
                @click="onClickRemove"
        >
        </b-button>
    </b-button-group>
</template>

<script>

    import { BButton, BButtonGroup } from 'bootstrap-vue'

    export default {
        name: "InputButton",
        components : {
            BButton,
            BButtonGroup
        },
        props: {
            id : {
                type : String,
                required: true
            },
            showRemove : {
                type: Boolean,
                default: false
            },
        },
        methods:{
            onClick(){
                this.$emit("open");
                // Sur mobile le bouton reste focus
                this.$refs.button.blur()
            },
            onClickRemove(){
                this.$emit("remove");
            }
        }
    }
</script>

<style scoped>

</style>
