<template>
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <b-button variant="link" class="annulationLink">
        <a :href="link" v-on:click="trackClick" target="_blank">{{ $t("Conditions d'annulation") }}</a>
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import {sendInterractionalEventGA4} from "Plugins/dataLayer/dataLayerHelper";
export default {
  name: 'CancellationPolicyLink',
  components:{
    BButton
  },
  props:{
    link : {
      type: String,
      required: true,
    }
  },
  methods:{
    trackClick(){
      sendInterractionalEventGA4({
        'site_section' : 'AE',
        'event_action' : 'Clic',
        'event_label' :  'Conditions d\'annulation'
      })
      trackNotContextualisedEventGA4(
          'mdv_cta',
          'mdv',
          'cgv',
          'click',
          {}
      )
    }
  }
}
</script>

<style scoped>

</style>
