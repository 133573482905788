<template>
   <functional-calendar
           :key="calendarKey"
           :configs="calendarConfigs"
           ref="calendar"
           @choseDay="clickDay"
           @changedMonth="changeDateToShow"
           :newCurrentDate="defaultDateToShow"
           v-on="$listeners"
   >
      <template v-slot:header-month="slotProps">
         <a href="#" :class="isActiveMonth(slotProps.calendarItem.date) ? 'active' : ''" @click.prevent="onClickMonth(slotProps.calendarItem.date)">{{ slotProps.calendarItem.month}} {{ slotProps.calendarItem.year}}</a>
      </template>
   </functional-calendar>
</template>

<script>
   /**    TODO :: On pourrait passer par un slot pour faciliter le code
    * Ici pas le temps de refactorer malheureusement
    * <div v-bind:class="getDayClasses(props.day)">
    {{ props.day.day }}
    </div>
    */

   import dayjs from 'dayjs'
   import { isSameDay, dateToStringDMY, stringDMYToDate, stringYMDToDate } from "Utils/date";
   import { FunctionalCalendar } from 'Plugins/vue-functional-calendar';
   import { maxStayEndDate, minStayStartDate } from 'Utils/stays/stays'

   const START_STAY = "start"
   const MIDDLE_STAY = "middle"
   const END_STAY = "end"
   const START_STAY_SELECTED = "start_selected"
   const MIDDLE_STAY_SELECTED = "middle_selected"
   const END_STAY_SELECTED = "end_selected"

    export default {
        name: "StayDatesCalendar",
        components : {
            FunctionalCalendar
        },
       data() {
          // Par default on affiche la date de début de "highlightedDates"
          const dateToShowSaved = this.highlightedDates.start ? stringYMDToDate(this.highlightedDates.start) : new Date()
          return {
             calendarKey : 0,
             isDatePicker: true,
             markedDate : {},
             monthNames : dayjs.months(),
            // Date interne, utilisé si pas de dateToShow fourni en props. Par default, le date de début du séjour actif
             dateToShowSaved
          }
       },
        props:{
          // force l'affichage de cette date
          // même si changement de mois du calendrier
          // Utlisé pour le changement de mois via le select dans le mr1 par exemple
           dateToShow : {
              type : String
           },
           stayDates : {
              type : Array
           },
           calendarsCount : {
                type: Number,
                default:1
           },
          // Peut être un période avec start, end ou un mois (month)
           highlightedDates:{
             type:  Object,
             default: {}
           }
        },
       computed:{
          weekdaysMin(){
             const [sunday, ...restOfWeek] = dayjs.weekdaysMin()
             return [...restOfWeek,sunday]
          },
          minMaxDate(){
            return {
              min: dayjs(minStayStartDate(this.stayDates)).format("D/M/YYYY"),
              max: dayjs(maxStayEndDate(this.stayDates)).format("D/M/YYYY")
            }
          },
          calendarConfigs(){
             return {
                transition : false,
                monthNames : this.monthNames,
                dayNames : this.weekdaysMin,
                limits:this.minMaxDate,
                isDatePicker: this.isDatePicker,
                isMultiple: this.hasMultipleCalendar,
                calendarsCount: this.calendarsCount,
                markedDates: this.markedDates
             }
          },
          markedDates(){
             /**
              * Le plugin s'attend a un array de date avec la classe correspondante
              * Pour l'instant on a un ensemble de séjour {debut -> fin}
              * On converti le tout en array[{dateJour, type}, {...}]
              * type = date debut, date de fin, date debut jour selectionné,..
              **/
             // converti les séjours
             const stayDays = this.stayDates.flatMap((stay) => (this.stayToDaysWithType(stay)))

             // on y ajoute le sejour selectionné
             const stayDayAndSelectedStay =  stayDays.concat(this.activeStayDays)
             // et on repasse sur chaque jour en groupant les doublons (séjour se superposant
             const marked =  this.regroupDayAndType(stayDayAndSelectedStay)
             // on converti en object que le plugin attend
             return Object.keys(marked).map(date => ({date : date, class : marked[date]}))
          },
          hasMultipleCalendar(){
             return this.calendarsCount > 1
          },
          activeStayDays(){
             if(!this.highlightedDates.start || !this.highlightedDates.end)
                return [];
             return this.stayToDaysWithType(this.highlightedDates,true);
          },
         // TODO :: nommage pas clair entre defaultDateToShow, dateToShow && dateToShowSaved
         defaultDateToShow(){
           if(this.dateToShow){
             return stringYMDToDate(this.dateToShow)
           }
           return this.dateToShowSaved
         },
       },
       methods:{
          isActiveMonth(date){
             if(this.highlightedDates.month){
                const activeMonthDate = stringYMDToDate(this.highlightedDates.month)
                if(activeMonthDate.getFullYear() === date.getFullYear() && activeMonthDate.getMonth() === date.getMonth()){
                   return true
                }
             }
             return false
          },
           onClickMonth(date) {
              this.$emit("selectMonth", date)
           },
           regroupDayAndType(staysDay){
              return staysDay.reduce((accumulator, stayDay) => {
                 if(!accumulator[stayDay.date]){
                    accumulator[stayDay.date] = stayDay.type
                 }else if(accumulator[stayDay.date] !== stayDay.type){
                    accumulator[stayDay.date] += " "+stayDay.type
                 }
                 return accumulator
              },{})
           },
           // TODO :: passer a une method qui passe en revue chaque jour du calendrier
          //  et regarde a quoi il correspond dans les séjours
          getDayClasses(){
            /*console.log(day)
             const curDayDate = dayjs(day.date)
             const classes = this.stayDates.flatMap(({start, end}) => {
                const startDate = dayjs(start)
                const endStay = dayjs(end)
                if(curDayDate.isSame(startDate))
                   return "START_STAY"


                dayjs(end)

             })*/
             return '';
          },
          dateToShowDMY(){
             return dayjs(this.defaultDateToShow).format("D/M/YYYY")
          },
          stayToDaysWithType({start, end},selected = false){
             const dateStart = stringYMDToDate(start),
                     dateEnd = stringYMDToDate(end),
                     dates = [];
             for(
                     const dayDate = new Date(dateStart.getTime());
                     dayDate <= dateEnd;
                     dayDate.setDate(dayDate.getDate()+1)
             ){
                dates.push({
                   date : dateToStringDMY(new Date(dayDate)),
                   type : this.getDayType(dayDate,dateStart, dateEnd, selected)
                });
             }
             return dates;
          },
          getDayType(dateDay, dateStart, dateEnd, selected = false){
              if(isSameDay(dateDay,dateStart))
                 return selected ? START_STAY_SELECTED : START_STAY
              if(isSameDay(dateDay,dateEnd))
                 return selected ? END_STAY_SELECTED :END_STAY
              return selected ? MIDDLE_STAY_SELECTED : MIDDLE_STAY
           },
           clickDay({date: dateString}){
             const dateClicked = stringDMYToDate(dateString, '/')
             this.$emit("clickDay",dateClicked)

             const dayClicked = dayjs(dateClicked).format("YYYY-MM-DD")
             const stays = this.stayDates.filter(({start}) => (start === dayClicked))
             if(stays.length > 0){
               this.$emit("clickStays",stays)
             }
           },
          changeDateToShow(date){
            this.dateToShowSaved = date
            this.$emit("changeDateToShow",date)
           }
       },
      // Le plugin n'est pas reactif au changement de date a affiché de séjour selectionné et au séjour disponible
      // On force le rerender via les watch
       watch : {
          dateToShow(){
             this.$refs.calendar.ChooseDate(this.dateToShowDMY());
          },
          // On doit pour l'instant forcer le re-render en changeant la clé du composant, clairement pas l'ideal
         highlightedDates(){
             this.calendarKey++;
          },
          stayDates(){
             this.calendarKey++;
          },
           calendarsCount(){
             this.calendarKey++;
           },
       }
    }
</script>

<style>

</style>
