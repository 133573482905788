<template>
  <i18n-n :value="amount" :format="{ key: 'currency', currency: 'EUR' }">
    <template v-slot:fraction="slotProps"><span class="fraction">{{ slotProps.fraction}}</span></template>
    <template v-slot:currency="slotProps"><span class="currency">{{ slotProps.currency}}</span></template>
  </i18n-n>
</template>

<script>
export default {
  name: 'PriceFormat',
  props : {
    amount: {
      required : true,
      type: Number,
    }
  }
}
</script>

<style scoped>

</style>
