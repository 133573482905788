<template>
  <div class="row stayPriceTotal">
    <div class="col-6">
      <span class="label">{{ $t("__TOTAL") }}</span></div>
    <div class="col-6 d-flex justify-content-end" @click="handleClickInputFees">
      <span class="value">
        <price-format :amount="price"/>
        <div class="detailPriceLabel">{{ $t("__Details du prix") }}</div>
      </span>
    </div>

    <product-stay-fee-modal/>
    <product-stay-fees-popover/>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ProductStayFeeModal from 'Components/container/Product/ProductStayFeesModal'
import ProductStayFeesPopover from 'Components/container/Product/ProductStayFeesPopover'
import PriceFormat from 'Components/ui/PriceFormat/PriceFormat'

export default {
  name: 'StayPriceWithFeesButton',
  components:{
    PriceFormat,
    ProductStayFeeModal,
    ProductStayFeesPopover
  },
  computed: {
    ...mapState('product/staySelected',{
      price : ({price}) => price
    })
  },
  methods : {
    ...mapActions({
      openInputFees : 'product/openInputFees'
    }),
    handleClickInputFees(){
      this.openInputFees()
    }
  }
}
</script>

<style scoped>

</style>
