const updateStaySelectedWhenGroupOrDateChange = (store) => {

  store.watch(
    state => state.product.stayGroup.personCount,
    () => store.dispatch("product/staySelected/update")
  )

  store.watch(
    state => state.product.stayGroup.dogCount,
    () => store.dispatch("product/staySelected/update")
  )

  store.watch(
    state => state.product.stayDate.stay,
    () => store.dispatch("product/staySelected/update")
  )

}
export default updateStaySelectedWhenGroupOrDateChange
