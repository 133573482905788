import BaseBind from "Utils/BaseBindClass"

export default class Paginator extends BaseBind{
  curPage = 1
  pageCount = 1
  onPageChange = null

  events = [
    ["click",   [".paginate-prev"], "prev"],
    ["click",   [".paginate-next"], "next"],
    ["click",   [".paginate-goTo"], "gotTo"],
  ]

  constructor({ el, $el, onPageChange }){
    super({ el, $el }).init()
    this.onPageChange = onPageChange
    this.pageCount = this.$el.querySelectorAll(".paginate-page").length
    this.setPagesClass()
  }
  next = () => {
    trackNotContextualisedEventGA4(
        'mdv_cta',
        'mdv',
        'arrow_new_page',
        'click',
        {'category_details': this.curPage+1}
    )
    this.changePage(this.curPage+1)
  }
  prev = () => {
    trackNotContextualisedEventGA4(
        'mdv_cta',
        'mdv',
        'arrow_new_page',
        'click',
        {'category_details': this.curPage-1}
    )
    this.changePage(this.curPage-1)
  }
  gotTo = (e) => {
    trackNotContextualisedEventGA4(
        'mdv_cta',
        'lr',
        'number_new_page',
        'click',
        {'category_details' : e.target.getAttribute("data-page")}
    )
    this.changePage(parseInt(e.target.getAttribute("data-page")))
  }
  changePage = (page) => {
    if(page >= this.pageCount){
      page = this.pageCount
    }

    if(page <= 0){
      page = 1
    }

    if(page === this.curPage){
      return;
    }

    this.curPage = page

    if(this.onPageChange){
      this.onPageChange()
    }

    this.setPagesClass()
    this.setDataAttributToPagination()
  }
  setDataAttributToPagination(){
    this.$el
      .querySelector(".pagination")
      .setAttribute("data-page",this.curPage)
  }
  setPagesClass = () => {
    this.$el
      .querySelectorAll(".paginate-page")
      .forEach(this.setPageClass)
  }
  setPageClass = (element) => {
    const elementPage = parseInt(element.getAttribute("data-page"));
    elementPage === this.curPage
      ? element.classList.add('open')
      : element.classList.remove('open');
  }
}
