<template>
  <div class="stayPrice">
    <stay-discount/>
    <stay-price-with-fees-button/>
    <stay-deposit/>
  </div>
</template>

<script>
import StayPriceWithFeesButton
  from 'Components/container/Product/ProductStayBox/components/container/StayPrice/components/container/StayPriceWithFeesButton'
import StayDeposit
  from 'Components/container/Product/ProductStayBox/components/container/StayPrice/components/container/StayDeposit'
import StayDiscount
  from 'Components/container/Product/ProductStayBox/components/container/StayPrice/components/container/StayDiscount'
export default {
  name: "StayPrice",
  components: {
    StayDiscount,
    StayDeposit,
    StayPriceWithFeesButton
  },

}
</script>

<style scoped>

</style>
