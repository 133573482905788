<template>
    <b-form-select
            :options="stayTypes"
            @change="change"
            :value="stayTypeSelected"
    />
</template>

<script>

    import { BFormSelect } from 'bootstrap-vue'
    export default {
        name: "StayTypeSelect",
        components : {
            BFormSelect
        },
        props :{
            stayTypes : {
                type: Array,
                required: true
            },
            stayTypeSelected : {
                type: String
            }
        },
        methods : {
            change(newStayType){
                this.$emit("change",newStayType)
            }
        }

    }
</script>

<style scoped>

</style>
