<template>
  <div>

    <div v-if="isMobile">

      <button
          v-if="isMobile"
          class="toggleStayPriceMobile"
          v-bind:class="{ open: isOpen}"
          @click="toggle">
      </button>
    </div>

    <div v-if="isOpen">
      <stay-date-detail/>
      <stay-group/>
      <stay-price/>
      <book-button/>
      <cancellation-policy-link :link="cancellationPolicyLink"/>
    </div>

    <product-stay-box-close v-else/>

  </div>
</template>

<script>

import {mapGetters, mapActions, mapState} from "vuex";

import StayDateDetail from 'Components/container/Product/ProductStayBox/components/container/StayDateDetail'
import StayGroup from 'Components/container/Product/ProductStayBox/components/container/StayGroupDetail'
import StayPrice from 'Components/container/Product/ProductStayBox/components/container/StayPrice'
import BookButton from 'Components/container/Product/ProductStayBox/components/container/BookButton'
import CancellationPolicyLink
  from 'Components/container/Product/ProductStayBox/components/ui/CancellationPolicyLink'
import ProductStayBoxClose
  from 'Components/container/Product/ProductStayBox/components/container/ProductStayBoxClose'

export default {
  name: "ProductStayBox",
  components:{
    ProductStayBoxClose,
    CancellationPolicyLink,
    BookButton,
    StayPrice,
    StayGroup,
    StayDateDetail
  },
  methods:{
    ...mapActions({toggle : 'product/togglePriceBox'}),
  },
  computed : {
    ...mapState('base', {
      cancellationPolicyLink: ({cancellationPolicyLink}) => cancellationPolicyLink,
    }),
    ...mapGetters({
      isMobile : "breakpoint/isMobile",
      isOpen : "product/isPriceBoxOpen"
    })
  }
}
</script>

<style scoped>

</style>
