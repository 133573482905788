import { render, staticRenderFns } from "./CancellationPolicyLink.vue?vue&type=template&id=53b6fa0d&scoped=true&"
import script from "./CancellationPolicyLink.vue?vue&type=script&lang=js&"
export * from "./CancellationPolicyLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53b6fa0d",
  null
  
)

export default component.exports