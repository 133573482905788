<template>
    <StayTypesSelect
            v-if="asSelect"
            :stayTypes="stayTypes"
            :stayTypeSelected="stayTypeSelected"
            v-on="$listeners"
    />
    <StayTypesBox
            v-else
            :stayTypes="stayTypes"
            :stayTypeSelected="stayTypeSelected"
            v-on="$listeners"
    />
</template>

<script>

    import StayTypesBox from "./components/ui/StayTypesBox";
    import StayTypesSelect from "./components/ui/StayTypesSelect";

    // input array ["id"=>"name"]
    // input -> pc ou mobile
    // mobile -> select box
    // pc -> box
    // output -> trigger function when stay selected
    // input -> selected element

    export default {
        name: "StayTypeSelector",
        components : {
            StayTypesBox,
            StayTypesSelect
        },
        props: {
            stayTypes : {
                type: Array,
                required: true
            },
            asSelect : {
                type: Boolean,
                default : true
            },
            stayTypeSelected : {
                type: String
            }
        },
        computed:{

        }
    }
</script>

<style scoped>

</style>
