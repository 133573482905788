<template>
    <b-popover
            :key="count"
            :custom-class="classes"
            :target="target"
            :placement="placement"
            :show="show"
            :disabled="true"
            :container="container"
            :fallback-placement="[placement]"
    >
        <div class="closeOnClickOutside" v-click-outside="close">
            <slot></slot>
        </div>
    </b-popover>

</template>
<script>
    import vClickOutside from 'v-click-outside'
    import { BPopover } from 'bootstrap-vue'

    const ManualPopover = {
        name:"ManualPopover",
        data(){
            return {
                count : 0,
            }
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        components: {
            BPopover
        },
        computed:{
          classes(){
              return `popover-${this.target}`
          }
        },
        props:{
            container:{
              type: String
            },
            target:{
              type: String,
              required: true
            },
            show : {
                type: Boolean,
                required :true
            },
            placement:{
                type: String,
                default: "bottom"
            },
        },
        methods:{
            close(){
                this.$emit("clickOutside")
            }
        },
        watch: {
            show(){
                this.count++;
            }
        }
    };
    export default ManualPopover
</script>


<style>

</style>
