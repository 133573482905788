<template>
  <div class="row">
    <div class="col-7" @click="togglePriceBox">
      <div class="row">
        <div class="col-6">
          <div class="labelPriceMobile"> {{ $t("__TOTAL") }}</div>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <div class="valuePriceMobile"> <price-format :amount="price"></price-format></div>
        </div>
      </div>
      <div class="row detailPriceMobile">
        <div class="col-12">
          {{ txtDate }}
          <div>{{ leavingHours}}</div>
        </div>
      </div>

    </div>
      <div class="col-5">
        <book-button-mobile/>
      </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getDateTextFromTo, getStayGroupTxt } from 'Utils/textFormat/stay'
import BookButtonMobile
  from 'Components/container/Product/ProductStayBox/components/container/BookButtonMobile'
import PriceFormat from 'Components/ui/PriceFormat/PriceFormat'

export default {
  name: 'ProductStayBoxClose',
  components: {
    PriceFormat,
    BookButtonMobile
  },
  computed:{
    ...mapState('product/stayGroup',{
      personCount : ({personCount}) => personCount,
      dogCount : ({dogCount}) => dogCount
    }),
    ...mapState('product/staySelected',{
      start : ({start}) => start,
      end : ({end}) => end,
      leavingHours : ({leavingHours}) => leavingHours,
      price : ({price}) => price,
    }),
    txtGroup(){
      return getStayGroupTxt({
        personCount : this.personCount,
        dogCount : this.dogCount
      }, true)
    },
    txtDate(dateString){
      return getDateTextFromTo({start : this.start, end : this.end, format : 'DD/MM/YY'})
    },
  },
  methods : {
    ...mapActions({
      togglePriceBox : 'product/togglePriceBox'
    })
  }
}
</script>

<style scoped>

</style>
