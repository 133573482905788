<template>
    <div class="row">
        <div class="col-12 d-flex justify-content-center">
            <b-button :href="bookingUrl" @click="trackClick" variant="link" class="BookButton" >{{ $t("Réservez maintenant !") }}</b-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { BButton } from 'bootstrap-vue'
import {sendInterractionalEventGA4} from "Plugins/dataLayer/dataLayerHelper";
export default {
  name: "BookButton",
  components:{
    BButton
  },
  computed : {
    ...mapGetters({
      bookingUrl : "product/bookingUrl",
    }),
  },
  methods:{
    trackClick(){
      track_add_to_cart();
      sendInterractionalEventGA4({
        'site_section' : 'AE',
        'event_action' : 'Bouton',
        'event_label' :  'Réserver'
      })
    }
  }

}
</script>

<style scoped>

</style>
