<template>
  <div>
    <stay-type-selector
        :asSelect="isMobile"
        :stayTypes="stayTypes"
        :stayTypeSelected="stayTypeSelected"
        @change="setStayTypeSelected"
    />
    <stay-dates-calendar
        :stayDates="stays"
        :calendarsCount="calendarsCount"
        @clickStays="handleClickStays"
        :highlightedDates="staySelectedCalendar"
    />

    <div class="messageCalendar" v-if="note">
      {{ note }}
    </div>
  </div>
</template>

<script>
/*
  Ancienne utilisation de Vv-calendar
  <stays-calendar
       :stays="stays"
       :staySelected="stay"
       :lang="lang"
       @selectStay="updateDate"
    />*/
import {mapState ,mapActions, mapGetters} from "vuex";

import StayTypeSelector from 'Components/ui/StayTypeSelector'
import StayDatesCalendar from 'Components/ui/StayDatesCalendar/StayDatesCalendar'

export default {
  name: "ProductStaySelector",
  components: {
    StayDatesCalendar,
    StayTypeSelector
  },
  computed: {
    ...mapGetters('breakpoint',{
      isMobile : 'isMobile'
    }),
    ...mapState('base',{
      lang: ({lang}) => lang
    }),
    ...mapState('product/staySelected',{
      note : ({note}) => note
    }),
    ...mapState('product/availableStays',{
      stays : ({stays}) => stays
    }),
    ...mapState('product/stayType', {
      stayTypeSelected: ({ type }) => type
    }),
    ...mapGetters({
      stay : 'product/stayDate/stay',
      isDesktop: 'breakpoint/isDesktop',
      stayTypes : "stayTypes/stayTypeAsOption",
    }),
    calendarsCount(){
      return this.isDesktop ? 2 : 1
    },
    staySelectedCalendar(){
      // On n'affiche le séjour sélectionné sur le calendrier
      // seulement si du même type que les séjours disponible affichés.
      if(this.stay && this.stayTypeSelected === this.stay.type){
        const {start, end} = this.stay
        return {start, end};
      }
      return {};
    }
  },
  methods:{
    ...mapActions({
      setStayTypeSelected : 'product/stayType/setStayTypeSelected',
      updateDate : 'product/stayDate/updateDate'
    }),
    handleClickStays(stays){
      // Il ne peut y avoir plusieurs séjour sur la fiche maison, on prend le 1er d'office
      this.updateDate(stays[0])
    },
  },
}
</script>

<style scoped>

</style>
