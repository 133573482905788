import Vue from 'vue'
import BaseBind from "Utils/BaseBindClass"
import Glide, {Controls, Keyboard, Swipe} from '@glidejs/glide/dist/glide.modular.esm'
import M from 'Constants/mutations-types'

/**
 * Gère la gallerie fullscreen et mobile de la fiche maison
 * La gallerie mobile n'est pas lazy loadée vu que faite avec glide et présent dans le bundle partagé
 * Par contre la gallerie fullscreen est instanciée et loadée seulement au click sur images
 */
export default class HousePhotoGallery extends BaseBind{

    glideMobile = null
    galleryFullscreenComponent = null
    galleryFullscreenVue = null
    media = [] // photo de la gallerie FS
    thumbsLoaded = false

    events = [
        ["click",   [".addFavoris"], "addRemoveFavoris"],
        ["click", [".open-fullscreen"], "openFullscreenGallery"]
    ]

    constructor({ el, store, data : { media }} ) {
        super({el, store}).init()

        this.media = media

        // au changement de breakpoint, on active/desactive gallerie fs ou mobile
        store.subscribe((mutation, {breakpoint: {currentBreakpoint}}) => {
            if (mutation.type === `breakpoint/${M.BREAKPOINT_CHANGE}`) {
                this.enableDisableMobileRegardingBreakpoint(currentBreakpoint)
            }
        });

        this.enableDisableMobileRegardingBreakpoint(store.state.breakpoint.currentBreakpoint)

        // On force le load du gallery component pour éviter
        // de se retrouver avec un bundle qui a été supprimé par une MAJ
        this.getFullscreenGalleryComponent()
        // if(!this.isMobile()){
        //   this.loadThumbnails()
        // }

    }
    addRemoveFavoris = (e) => {
            e.preventDefault();
        const EventHandler = e.currentTarget;

        const infoSejour = {
            IDLocation  : EventHandler.dataset.idlocation,
            TypeSejour  : EventHandler.dataset.typesejour,
            DateFin     : EventHandler.dataset.datefin,
            DateDebut   : EventHandler.dataset.datedebut,
            NbrPers     : EventHandler.dataset.nbrpers,
            NbrAnim     : EventHandler.dataset.nbranim,
        };

        this.store.dispatch('favorites/toggleFavorite', infoSejour);
        e.target.classList.toggle('added');
    }
    async enableFullscreenGalleryDisableMobile(){
        if(!this.galleryFullscreenVue){
            await this.setupFullscreenGallery()
        }
    }
    enableDisableMobileRegardingBreakpoint(breakpoint){
        (breakpoint == "sm" || breakpoint == "xs")
          ? this.enableMobileGallery()
          : this.disableMobileGallery()

    }
    async getFullscreenGalleryComponent(){
        if(!this.galleryFullscreenComponent){
            const {default : GalleryFullscreen} = await import("Components/ui/GalleryFullscreen");
            this.galleryFullscreenComponent = GalleryFullscreen
        }
        return this.galleryFullscreenComponent
    }

    async setupFullscreenGallery(){
        const media = this.media
        const GalleryFullscreen = await this.getFullscreenGalleryComponent()

        this.galleryFullscreenVue = new Vue({
            el: '#house-gallery-fullscreen',
            render: function(createElement) {
                return createElement(GalleryFullscreen, {
                    ref : 'galleryFullscreen',
                    props:{ media }
                });
            },
            components: { GalleryFullscreen }
        });
    }

    setupMobileGallery(){
        this.glideMobile = new Glide("#HousePhotoGallery", {
            startAt: 0,
            perView: 1,
            gap: 8,
            bound: true
        }).mount({Controls, Swipe, Keyboard})
    }

    openFullscreenGallery = (e) => {
        const index = e.target.getAttribute("data-image-index")

        this.openFullscreenAt(index ? parseInt(index) : 0)
        trackBasicEventGA4(
            'mdv_cta',
            {
                category: 'gallery',
                website_subsection: 'mdv',
                action: 'click',
            }
        )
    }

    async openFullscreenAt(index){
        await this.enableFullscreenGalleryDisableMobile()
        // if(!this.isMobile()){
        //   await this.loadThumbnails()
        // }
        this.galleryFullscreenVue.$refs.galleryFullscreen.open(index)

    }
    enableMobileGallery(){
        if(!this.glideMobile){
            this.setupMobileGallery()
            return;
        }
        this.glideMobile.enable();
    }

    disableMobileGallery(){
        if(this.glideMobile){
            this.glideMobile.disable()
        }
    }
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    async loadThumbnails() {
      if(this.thumbsLoaded){
        return;
      }
      try {
        const imagePromises = this.thumbs().map(url =>
          new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = resolve
            img.onerror = reject
            img.src = url
          })
        )

        await Promise.all(imagePromises)
      } catch (error) {
        console.error('Error loading thumbnails:', error)
      }
      this.thumbsLoaded = true
    }

    thumbs() {
      return this.media.map(item => item.thumb);
    }
}
