<template>
  <b-button :href="bookingUrl" v-on:click="trackClick" variant="link" class="BookButton" >{{ $t("__Réservez !") }}</b-button>

</template>

<script>
import { mapGetters } from "vuex"
import { BButton } from 'bootstrap-vue'
import {sendInterractionalEventGA4} from "Plugins/dataLayer/dataLayerHelper";

export default {
  name: "BookButtonMobile",
  components:{
    BButton
  },
  computed : {
    ...mapGetters({
      bookingUrl : "product/bookingUrl",
    }),
  },
  methods:{
    trackClick(){
      track_add_to_cart();
      sendInterractionalEventGA4({
        'site_section' : 'AE',
        'event_action' : 'Bouton',
        'event_label' :  'Réserver'
      })
    }
  }
}
</script>

<style scoped>

</style>
