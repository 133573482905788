<template>
    <div class="spinnerDefault">
        <b-spinner :label="label"></b-spinner>
    </div>
</template>

<script>
    import { BSpinner}  from "bootstrap-vue"
    export default {
        name: "Spinner",
        components : {
            BSpinner
        },
        props:{
            label : {
                type: String,
                default : "Loading..."
            }
        }
    }
</script>

<style scoped>

</style>
