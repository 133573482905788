import BaseBind from 'Utils/BaseBindClass'
import { translate } from 'API/translation'
import {sendInterractionalEventGA4} from 'Plugins/dataLayer/dataLayerHelper';

export default class Translatable extends BaseBind{
  translated = false
  language = null
  events = [
    ["click",   [".translate"], "translate"],
  ]

  constructor({ $el, language }){
    super({ $el }).init()
    this.language = language
  }
  translate = async () => {
    sendInterractionalEventGA4({
      'site_section' : 'AE',
      'eventAction' : 'Clic',
      'eventLabel' :  'Traduire commentaires'
    })

    if(this.translated){
      this.toggle()
      return;
    }

    const toTranslate = this.$el.querySelector(".toTranslate").textContent
    this.getTranslationContainer().textContent = await translate(toTranslate, this.language)
    this.translated = true
  }
  getTranslationContainer(){
    return this.$el.querySelector(".translation-container")
  }
  toggle(){
    this.getTranslationContainer().classList.toggle("close")
  }
}
