import BaseBind from "Utils/BaseBindClass"
import Paginator from 'Classes/ui/Paginator'
import Translatable from 'Classes/ui/Translatable'
import {sendInterractionalEventGA4} from "Plugins/dataLayer/dataLayerHelper";

export default class HouseReviews extends BaseBind{
  constructor({ el, store }){
    super({ el, store }).init()

    this.initPaginator()
    this.initReviewsTranslation()

  }
  initPaginator(){
    const $el = this.$el.querySelector(".paginator")
    // Il se peut qu'il n'y ait pas d'avis et pas de pagination
    if(!$el){
      return;
    }

    const paginator = new Paginator({
      $el,
      onPageChange : this.handlePageChange
    })
  }
  handlePageChange = () => {
    this.trackPageChange()
    this.anchorToReview()
  }
  trackPageChange(){
    sendInterractionalEventGA4({
      'site_section' : 'AE',
      'eventAction' : 'Clic',
      'eventLabel' :  'Pagination avis'
    })
  }
  anchorToReview(){
    location.href = `#comment`
  }
  initReviewsTranslation(){
    this.$el.querySelectorAll(".translatable").forEach(this.initElementTranslation)
  }
  initElementTranslation = (element) => {
    new Translatable({$el : element, language : this.store.state.base.lang })
  }
}
