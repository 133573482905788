<template>
    <b-form-group>
        <b-form-radio-group
                :options="stayTypes"
                buttons
                button-variant="outline-primary"
                name="stayTypeSelected"
                :checked="stayTypeSelected"
                @change="change"
        ></b-form-radio-group>
    </b-form-group>
</template>

<script>

    import {
        BFormGroup,
        BFormRadioGroup
    } from 'bootstrap-vue'

    export default {
        name: "StayTypesBox",
        components : {
            BFormGroup,
            BFormRadioGroup
        },
        props :{
            stayTypes : {
                type: Array,
                required: true
            },
            stayTypeSelected : {
                type: String
            }
        },
        methods : {
            change(newStayType){
                this.$emit("change",newStayType)
            }
        }
    }
</script>

<style scoped>

</style>
