<template>
    <input-button
            id="stay-group-button"
            :showRemove="hasCompleted"
            v-on="$listeners"
    >
        <div class="group">{{ txt }}</div>
    </input-button>
</template>

<script>

    import InputButton from "../InputButton";
    import { getStayGroupTxt } from "Utils/textFormat/stay";

    export default {
        name: "StayGroupButton",
        components : {
            InputButton
        },
        props: {
            hasCompleted : Boolean,
            personCount : {
                type: Number
            },
            dogCount : {
                type: Number
            }
        },
        computed: {
            txt(){
                return getStayGroupTxt({
                    personCount : this.personCount,
                    dogCount : this.dogCount
                }, true)
            }
        }
    }
</script>

<style scoped>

</style>
