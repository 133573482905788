<template>
  <div v-if="html" v-html="html">Loading</div>
  <Spinner v-else/>
</template>

<script>

import { mapState } from "vuex"
import { getRentalFeesForStayWithPriceHtml } from 'API/product'
import Spinner from 'Components/ui/Spinner/Spinner'
export default {
  name: 'ProductStayFees',
  components: { Spinner },
  data : () => {
    return {
      html : ''
    }
  },
  computed : {
    ...mapState('product/staySelected',{
      stay_id :  ({stay_id}) => stay_id,
    }),
    ...mapState('product/stayGroup',{
      personCount : ({personCount}) => personCount,
      dogCount : ({dogCount}) => dogCount
    })
  },
  methods:{
    async loadHtml(){
      this.html = await getRentalFeesForStayWithPriceHtml({
        stay_id : this.stay_id,
        personCount : this.personCount,
        dogCount : this.dogCount
      })
    }
  },
  mounted(){
    this.loadHtml()
  }
}
</script>

<style scoped>

</style>
