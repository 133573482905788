<template>
  <div class="priceButton" id="stay-date-detail-container" @click="openInputDates">
    <div class="selectDate">
      <div class="date">{{ dateFormat }}</div>
      <span>{{ leavingHours}}</span>
    </div>

    <product-stay-selector-popover/>
    <product-stay-selector-modal/>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"

import ProductStaySelectorPopover from 'Components/container/Product/ProductStaySelectorPopover'
import ProductStaySelectorModal from 'Components/container/Product/ProductStaySelectorModal'
import { getDateTextFromTo } from 'Utils/textFormat/stay'
export default {

  name: "StayDateDetail",
  components: {
    ProductStaySelectorPopover,
    ProductStaySelectorModal
  },
  computed : {
    dateFormat(dateString){
      return getDateTextFromTo({start : this.start, end : this.end, format : 'dd DD/MM/YY'})
    },
    ...mapState('product/staySelected',{
      start : ({start}) => start,
      end : ({end}) => end,
      leavingHours : ({leavingHours}) => leavingHours,
    })
  },
  methods : {
    ...mapActions({
      openInputDates : 'product/openInputDates'
    })
  },

}
</script>

<style scoped>

</style>
