<template>
  <stay-group-form
      :personCountMax="personCountMax"
      :dogCountMax="dogCountMax"
      :personCount="personCount"
      :dogCount="dogCount"
      @personCountChange="setPersonCount"
      @dogCountChange="setDogCount"
  />
</template>

<script>

import { mapActions, mapState } from 'vuex'
import StayGroupForm from 'Components/ui/StayGroupForm'

export default {
  name: "ProductStayGroupForm",
  components: {
    StayGroupForm
  },
  computed: {
    ...mapState('product',{
      personCountMax : ({personCountMax}) => (personCountMax),
      dogCountMax : ({dogCountMax}) => (dogCountMax),
      personCount : ({stayGroup : {personCount}}) => (personCount),
      dogCount : ({stayGroup : {dogCount}}) => (dogCount)
    })
  },
  methods :{
    ...mapActions({
      setPersonCount:'product/stayGroup/setPersonCount',
      setDogCount:'product/stayGroup/setDogCount'
    })
  },
}
</script>
