function updateUrlResource(urlResource){
  history.replaceState({}, "", urlResource);
}

const updateUrlWhenStaySelectedChange = (store) => {
  store.watch(
    state => state.product.staySelected.urlResource,
    updateUrlResource
  )
}

export default updateUrlWhenStaySelectedChange
