<template>
  <div class="row stayPriceDetail">
    <div class="col-6">
        <span class="label">
          {{ $t("__AEV5_Caution non incluse dans le prix total") }}
          <span v-if="deposit.payableOnSite">{{ $t("A payer sur place") }}</span>
        </span></div>
    <div class="col-6 d-flex justify-content-end">
      <span class="value d-flex align-items-center"><price-format :amount="deposit.amount"/></span>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex"
import PriceFormat from 'Components/ui/PriceFormat/PriceFormat'

export default {
  name: 'StayDeposit',
  components: { PriceFormat },
  computed: {
    ...mapState('product/staySelected',{
      deposit : ({deposit}) => deposit
    })
  }
}
</script>

<style scoped>

</style>
