import BaseBind from 'Utils/BaseBindClass'
import {sendInterractionalEventGA4} from "Plugins/dataLayer/dataLayerHelper";

export default class Product extends BaseBind {

    noiseLevelModal = null

    events = [
        [
            "click",
            [".noiseLevel"],
            "openNoiseLevel"
        ],
        [
            "click",
            ["#openAvisFromCote"],
            "trackOpenAvisFromCote"
        ],
        [
            "click",
            [".ButtonMoreDetail"],
            "trackBbuttonMoreDetail"
        ],
        [
            "click",
            [".translate"],
            "trackTranslate"
        ]
    ]

    constructor({el, i18n ,store, data}) {
        super({el, i18n, store}).init()
        this.fixExternalLinks()
    }

    getContentNoiseLevel(){
        const container = this.$el.querySelector("#noiseLevel-container");
        if(!container){
            console.error("No DOM element found for noiseLevel-container")
            return;
        }
        return container.innerHTML;
    }
    openNoiseLevel = (e) => {
        sendInterractionalEventGA4({
            'site_section' : 'AE',
            'eventAction' : 'Clic',
            'eventLabel' :  'niveau sonore'
        })
        this.store.dispatch("modals/openGeneric",this.getContentNoiseLevel(), this.i18n.t("Niveaux sonores"));
    }
    trackOpenAvisFromCote = () => {
        trackNotContextualisedEventGA4(
            'mdv_cta',
            'mdv',
            'read_comments',
            'click',
            {}
        )
    }
    trackBbuttonMoreDetail = () => {
        trackNotContextualisedEventGA4(
            'mdv_cta',
            'mdv',
            'see_details',
            'click',
            {}
        )
    }
    trackTranslate = () => {
        trackNotContextualisedEventGA4(
            'mdv_cta',
            'mdv',
            'translate',
            'click',
            {}
        )
    }

    fixExternalLinks = () => {
        const allLinks = this.$el.querySelectorAll(".seoDescription a")
        allLinks.forEach(link => {
            link.setAttribute('target', '_blank')
        })
    }
}
