<template>
  <section class="groupCompositionForm">
    <b-form-group
        label-cols-sm="6"
        label-cols-lg="6"
        label-for="personCountInput">
      <template #label>
        <div v-html="$t('__AEV5_personnes')"></div>
      </template>
      <b-form-spinbutton
          inline
          id="personCountInput"
          :value="personCount"
          @change="personCountChange"
          :min="1"
          :max="personCountMax"
         :class="{disableArrowUp:personCount==personCountMax}"
      />

    </b-form-group>

    <b-form-group
        v-if="showDog"
        label-cols-sm="6"
        label-cols-lg="6"
        :label="$t('__SEO_RECHERCHE_Animaux')"
        label-for="dogCountInput">
      <b-form-spinbutton
          inline
          id="dogCountInput"
          :value="dogCount"
          @change="dogCountChange"
          :min="0"
          :max="dogCountMax"
          :class="{disableArrowUp:dogCount==dogCountMax}"/>
    </b-form-group>
  </section>
</template>

<script>

import { BFormGroup, BFormSpinbutton } from 'bootstrap-vue'

export default {
  name: "StayGroupForm",
  components: {
    BFormGroup,
    BFormSpinbutton
  },
  props:{
    personCountMax : {
      type : Number,
      required : true
    },
    dogCountMax : {
      type : Number,
      required : true
    },
    personCount: {
      type : Number,
      default : 1
    },
    dogCount :{
      type : Number,
      default : 0
    }
  },
  methods :{
    personCountChange(personCount){
      this.$emit("personCountChange",personCount)
    },
    dogCountChange(dogCount){
      this.$emit("dogCountChange",dogCount)
    }
  },
  computed : {
    showDog(){
      return this.dogCountMax > 0;
    }
  }
}
</script>
