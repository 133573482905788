<template>
    <manual-popover
        :show="show"
        target="product-stay-group-container"
        @clickOutside="closeInput"
        placement="leftbottom"
    >
        <product-stay-fees/>
    </manual-popover>
</template>
<script>

    import { mapActions, mapGetters } from 'vuex'
    import ManualPopover from "Components/ui/ManualPopover"
    import ProductStayFees from 'Components/container/Product/ProductStayFees'

    export default {
        name:'ProductStayFeesPopover',
        components: {
            ManualPopover,
          ProductStayFees,
        },
        computed: {
            ...mapGetters({
                show : 'product/showPopoverFees',
            }),
        },
        methods : {
            ...mapActions({
                closeInput : 'product/closeInput'
            })
        }
    }
</script>
