<template>
  <manual-popover
      :show="show"
      target="stay-date-detail-container"
      @clickOutside="closeInput"
      placement="leftbottom"
  >
    <product-stay-selector/>
  </manual-popover>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import ManualPopover from "Components/ui/ManualPopover"
import ProductStaySelector from 'Components/container/Product/ProductStaySelector'

export default {
  name:'ProductStaySelectorPopover',
  components: {
    ManualPopover,
    ProductStaySelector,
  },
  computed: {
    ...mapGetters({
      show : 'product/showPopoverDates',
    }),
  },
  methods : {
    ...mapActions({
      closeInput : 'product/closeInput'
    })
  }
}
</script>
