<template>
  <div class="priceButton" id="product-stay-group-container">
    <stay-group-button
        :personCount="personCount"
        :dogCount="dogCount"
        @open="openInputGroup"
    />
    <product-stay-group-modal/>
    <product-stay-group-popover/>
  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from "vuex"

import ProductStayGroupModal from 'Components/container/Product/ProductStayGroupModal'
import ProductStayGroupPopover from 'Components/container/Product/ProductStayGroupPopover'
import StayGroupButton
  from 'Components/container/MR1/components/container/FormMR1/components/ui/StayGroupButton'
export default {
  name: "StayGroupDetail",
  components : {
    ProductStayGroupModal,
    ProductStayGroupPopover,
    StayGroupButton
  },
  computed : {
    ...mapState('product/stayGroup',{
      personCount : ({personCount}) => personCount,
      dogCount : ({dogCount}) => dogCount
    })
  },
  methods : {
    ...mapActions({
        openInputGroup : 'product/openInputGroup'
    })
  },
}
</script>

<style scoped>

</style>
