import Vue from 'vue'

import { commonWrapper, initTooltip } from 'Utils/helpers'
import Slider from 'Classes/ui/Slider'
import ProductStayBox from 'Components/container/Product/ProductStayBox'
import ProductStaySelector from 'Components/container/Product/ProductStaySelector'
import HousePhotoGallery from 'Classes/ui/HousePhotoGallery'
import Product from 'Classes/container/Product'
import HouseReviews from 'Classes/ui/HouseReviews'
import availableStaysPlugin from 'Plugins/product/availableStaysPlugin'
import updateStaySelectedWhenGroupOrDateChange from 'Plugins/product/updateStaySelectedWhenGroupOrDateChange'
import updateUrlWhenStaySelectedChange from 'Plugins/product/updateUrlWhenStaySelectedChange'

commonWrapper(({store, i18n, data}) => {

  availableStaysPlugin(store)
  updateStaySelectedWhenGroupOrDateChange(store)
  updateUrlWhenStaySelectedChange(store)

  // R�cup�ration des s�jours � l'ouverture de la page
  store.dispatch("product/availableStays/updateAvailableStays");

  new Product({
        el: "#product-container",
        store,
        data,
        i18n
    })

    // Reviews
    new HouseReviews({
      el : "#comment",
      store
    })



    // Photo Gallery
    new HousePhotoGallery({
        el : "#gallery",
        store,
        data
    })

    // CROSS SELL

    new Slider({
        el: "#fm-accommodation",
        store,
        ga4DataForSlideEvent: {
            eventName: 'mdv_cta',
            website_subsection: 'mdv',
            category: 'arrow',
            action: 'click',
        }
    });

    new Slider({
        el: "#fm-activity",
        store,
        ga4DataForSlideEvent: {
            eventName: 'mdv_cta',
            website_subsection: 'mdv',
            category: 'arrow',
            action: 'click',
        }
    });
    new Slider({
        el: "#fm-hikes",
        store,
        ga4DataForSlideEvent: {
            eventName: 'mdv_cta',
            website_subsection: 'mdv',
            category: 'arrow',
            action: 'click',
        }
    });
    new Slider({
        el: "#fm-mustSee",
        store,
        ga4DataForSlideEvent: {
            eventName: 'mdv_cta',
            website_subsection: 'mdv',
            category: 'arrow',
            action: 'click',
        }
    });





    // Calendar
    new Vue({
      el: '#calendar',
      store,
      i18n,
      render: function(createElement) {
          return createElement(ProductStaySelector);
      },
      components: { ProductStaySelector }
    });

    // Stay box
    new Vue({
      el: '#stayBox',
      store,
      i18n,
      render: function(createElement) {
        return createElement(ProductStayBox);
      },
      components: { ProductStayBox }
    });

    initTooltip();
})
