<template>
    <b-modal
        no-fade
        @close="closeInput"
        :visible="isVisible"
        no-close-on-backdrop
        no-close-on-esc
        :title="title"

    >
        <product-stay-group-form/>

      <template v-slot:modal-footer>
        <confirm-button @click="closeInput"/>
      </template>
    </b-modal>
</template>
<script>

    import { BModal } from 'bootstrap-vue'
    import { mapGetters, mapActions } from 'vuex'
    import ConfirmButton from 'Components/ui/form/ConfirmButton/ConfirmButton'

    import ProductStayGroupForm from 'Components/container/Product/ProductStayGroupForm'

    export default {
        name:'StayGroupModal',
        components: {
            BModal,
            ProductStayGroupForm,
            ConfirmButton
        },
        computed: {
            title(){
                return this.$t("Avec qui partez-vous ?")
            },
            ...mapGetters({
                isVisible : 'modals/productStayGroupShow'
            })
        },
        methods :{
            ...mapActions({
                closeInput:'product/closeInput',
            })
        }
    };
</script>
